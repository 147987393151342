import { create } from "zustand";

type AuthTokenStoreState = {
  token: string | null;
  loggedInAsOtherUser: boolean;
};

type AuthTokenStoreActions = {
  setToken: (newToken: string) => void;
  removeToken: () => void;
  setLoggedInAsOtherUser: (val: boolean) => void;
};

const useAuthTokenStore = create<AuthTokenStoreState & AuthTokenStoreActions>(
  (set) => ({
    token: null,
    setToken: (newToken) => set({ token: newToken }),
    removeToken: () => set({ token: null }),
    loggedInAsOtherUser: false,
    setLoggedInAsOtherUser: (val: boolean) => set({ loggedInAsOtherUser: val }),
  })
);

export default useAuthTokenStore;
