import { Outlet, useLocation } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import React, { useState, createContext, useEffect, useContext } from "react";
import Notifications from "./Notifications";
import useIsMobile from "../hooks/useIsMobile";
import useAccess from "../hooks/access";
import OfflineNotice from "./Offline";
import useIsOnline from "../hooks/useIsOnline";
import { UserContext } from "../App";
import FiltersContextProvider from "./Filters/FilterContextProvider";

const LayoutContext = createContext<{
  leftMenu: {
    forceExpanded: boolean;
    setForceExpanded: (value: boolean) => void;
    hovered: boolean;
    setHovered: (value: boolean) => void;
  };
}>({
  leftMenu: {
    forceExpanded: false,
    setForceExpanded: () => {},
    hovered: false,
    setHovered: () => {},
  },
});

const Layout: React.FC = () => {
  const { currentUser } = useContext(UserContext);
  const [leftMenuForceExpanded, setLeftMenuForceExpanded] = useState(false);
  const [leftMenuHovered, setLeftMenuHovered] = useState(false);
  const isMobile = useIsMobile();
  const { isAdmin } = useAccess();

  const { isOnline } = useIsOnline();
  const [displayOffline, setDisplayOffline] = useState(false);

  const currentLocation = useLocation();
  const [previousLocation, setPreviousLocation] = useState(currentLocation);

  useEffect(() => {
    // We only want to display the offline notice
    // when the user is navigating to a new page
    if (currentLocation.pathname === previousLocation.pathname) {
      return;
    }
    setDisplayOffline(!isOnline);
    setPreviousLocation(currentLocation);
  }, [currentLocation, previousLocation, isOnline]);

  if (!currentUser) return <></>;

  return (
    <>
      <LayoutContext.Provider
        value={{
          leftMenu: {
            forceExpanded: leftMenuForceExpanded,
            setForceExpanded: setLeftMenuForceExpanded,
            hovered: leftMenuHovered,
            setHovered: setLeftMenuHovered,
          },
        }}
      >
        <FiltersContextProvider>
          <div className="h-full flex flex-col max-h-full ">
            <div className="grow flex flex-row w-full max-h-full">
              <LeftMenu />
              <div className="w-full max-h-full overflow-y-hidden bg-gradient-to-br from-[#fafafa] to-purple-50 h-full">
                {displayOffline ? <OfflineNotice /> : <Outlet />}
              </div>
            </div>
          </div>
        </FiltersContextProvider>
      </LayoutContext.Provider>

      {!isMobile && !isAdmin && <Notifications />}
    </>
  );
};

export default Layout;
export { LayoutContext };
