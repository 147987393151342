import { createContext, useEffect, useMemo, useRef, useState } from "react";
import {
  DashboardFilters,
  EvaluationItemFilter,
  MinMaxFilter,
} from "../../models/filters";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

type CampaignFilters = {
  topics?: string[];
  conversationsMetadata?: {
    [key: string]: string[];
  };
  evalItems?: EvaluationItemFilter[];
  score?: MinMaxFilter;
};

export const FiltersContext = createContext<{
  filters: DashboardFilters;
  setFilters: React.Dispatch<React.SetStateAction<DashboardFilters>>;
  resetFilters: () => void;
}>({
  filters: {
    timeRange: {
      start: dayjs().subtract(1, "month").startOf("day"),
      end: dayjs().endOf("day"),
    },
  },
  setFilters: () => {},
  resetFilters: () => {},
});

function parseStoredHomeFilters(filterString: string) {
  const loadedFilters = JSON.parse(filterString) as Omit<
    DashboardFilters,
    "timeRange"
  > & {
    timeRange: {
      start: string;
      end: string;
    };
  };

  const filters: DashboardFilters = {
    ...loadedFilters,
    timeRange: {
      start: dayjs(loadedFilters.timeRange.start),
      end: dayjs(loadedFilters.timeRange.end),
    },
  };

  return filters;
}

const FiltersContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [homeFiltersLoadedFromStorage, setHomeFiltersLoadedFromStorage] =
    useState(false);
  const { campaignId } = useParams();
  const previousCampaignId = useRef<string>();
  const [filters, setFilters] = useState<DashboardFilters>({
    timeRange: {
      start: dayjs().subtract(1, "month").startOf("day"),
      end: dayjs().endOf("day"),
    },
  });

  const campaignFilters = useMemo<CampaignFilters>(
    () => ({
      topics: filters.topics,
      conversationsMetadata: filters.conversationsMetadata,
      evalItems: filters.evalItems,
      score: filters.score,
    }),
    [
      filters.topics,
      filters.conversationsMetadata,
      filters.evalItems,
      filters.score,
    ]
  );

  const nActiveCampaignFilters = useMemo(
    () =>
      Object.entries(campaignFilters).filter(([_, v]) => v !== undefined)
        .length,
    [campaignFilters]
  );

  const homeDashboardFilters: DashboardFilters = useMemo(
    () => ({
      timeRange: filters.timeRange,
      agents: filters.agents,
      campaigns: filters.campaigns,
      metadata: filters.metadata,
    }),
    [filters.timeRange, filters.agents, filters.campaigns, filters.metadata]
  );

  useEffect(() => {
    if (homeFiltersLoadedFromStorage) return;
    const storedFilters = localStorage.getItem("filters-home");
    if (storedFilters) {
      setFilters(parseStoredHomeFilters(storedFilters));
    }
    setHomeFiltersLoadedFromStorage(true);
  }, [homeFiltersLoadedFromStorage]);

  useEffect(() => {
    if (!homeFiltersLoadedFromStorage) return;
    if (previousCampaignId.current === campaignId) return;

    if (campaignId === undefined) {
      setFilters((prev) => ({
        ...prev,
        topics: undefined,
        conversationsMetadata: undefined,
        evalItems: undefined,
        score: undefined,
      }));
      previousCampaignId.current = undefined;
      return;
    }
    const storedCampaignFilters = localStorage.getItem(`filters-${campaignId}`);

    if (storedCampaignFilters === null) {
      setFilters((prev) => ({
        ...prev,
        topics: undefined,
        conversationsMetadata: undefined,
        evalItems: undefined,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        ...JSON.parse(storedCampaignFilters),
      }));
    }
    previousCampaignId.current = campaignId;
  }, [homeFiltersLoadedFromStorage, campaignId]);

  useEffect(() => {
    if (!homeFiltersLoadedFromStorage) return;
    if (!campaignId) return;

    if (nActiveCampaignFilters === 0) {
      localStorage.removeItem(`filters-${campaignId}`);
      return;
    }

    localStorage.setItem(
      `filters-${campaignId}`,
      JSON.stringify(campaignFilters)
    );
  }, [
    homeFiltersLoadedFromStorage,
    campaignId,
    campaignFilters,
    nActiveCampaignFilters,
  ]);

  useEffect(() => {
    if (!homeFiltersLoadedFromStorage) return;
    localStorage.setItem("filters-home", JSON.stringify(homeDashboardFilters));
  }, [homeFiltersLoadedFromStorage, homeDashboardFilters]);

  const resetFilters = () => {
    for (var key in localStorage) {
      if (key.startsWith("filters-")) {
        localStorage.removeItem(key);
      }
    }

    setFilters({
      timeRange: {
        start: dayjs().subtract(1, "month").startOf("day"),
        end: dayjs().endOf("day"),
      },
    });
  };

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
export default FiltersContextProvider;
